import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import ServiceSectionDetailed from "../components/service-section-detailed";

export default ({ data, pageContext }) => {
  let service = data.service;
  service = {
    title: service.frontmatter.title,
    excerpt: service.excerpt,
    description: service.frontmatter.description || service.excerpt,
    html: service.html,
  };
  const site = {
    url: data.site.siteMetadata.siteUrl + pageContext.slug,
    ...data.site.siteMetadata,
  };

  return (
    <Layout
      pageContext={pageContext}
      title={service.title}
      lang={pageContext.lang}
      description={service.description}
      schema={`
      {
        "@context": "http://schema.org",
        "@type": "Product",
        "name": "${service.title}",
        "description": "${service.description}",
        "brand": "${site.author}"
      }
      `}
    >
      <ServiceSectionDetailed service={service} />
    </Layout>
  );
};

export const query = graphql`
  query serviceQuery($slug: String!) {
    service: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
      }
      excerpt(pruneLength: 280)
      html
    }

    site {
      siteMetadata {
        siteUrl
        title
        author
      }
    }
  }
`;
