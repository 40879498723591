import styled from "@emotion/styled";
import tw from "twin.macro";

export default styled.section`
  ${tw`flex flex-col flex-wrap items-center justify-center p-4 pb-16 md:flex-row md:pb-32`}
`;

export const Row = styled.div`
  ${tw`flex justify-center w-full`}
`;
