import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import tw from "twin.macro";

import SectionTemplate from "./section";

const ServiceSectionDetailed = ({ service }) => (
  <Section>
    <Title>{service.title}</Title>
    <Description dangerouslySetInnerHTML={{ __html: service.html }} />
  </Section>
);
ServiceSectionDetailed.propTypes = {
  service: PropTypes.shape({
    title: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired,
  }),
};

const Section = styled(SectionTemplate)`
  ${tw`my-8`}
`;
const Title = styled.h1`
  ${tw`mb-4 text-center text-primary-500 w-full`}
`;
const Description = styled.div`
  ${tw`flex flex-col flex-wrap items-stretch justify-center my-2`}
  max-width: 1080px;

  & div {
    ${tw`flex-1 max-w-lg p-8 mb-4 bg-white rounded shadow-lg sm:m-4 sm:w-auto`}
  }

  & div h4 {
    ${tw`leading-tight text-primary-500`}
  }

  & div ul {
    ${tw`ml-2 leading-relaxed list-disc sm:ml-4 md:ml-8`}
  }

  & div h4 + * {
    ${tw`mt-4`}
  }
`;

export default ServiceSectionDetailed;
